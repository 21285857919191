import React from "react"
import { Container, Button } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"

import postDrywall from "../pdf/install-requirements/WAB-postdrywall-checklist.pdf"
import preDryWall from "../pdf/install-requirements/WAB-predrywall-checklist.pdf"

const aboutTheAuthor = props => (
  <Layout>
        <Seo title="Install Requirements"/>
        <section className="page-section">
          <Container>
          <h1>Install Requirements</h1>
          </Container>
          <Container className="singleCol">
           <h3>Required Conditions for An AeroBarrier Install</h3>
           <p className="lead">AeroBarrier may be installed at either PRE-DRYWALL or POST-DRYWALL stages. This document is divided into two separate sections for each type of installation.</p>
           <h3>Post-Drywall</h3>
           <p><strong>When This Installation Takes Place</strong></p>
           <ul>
             <li>AeroBarrier installation is optimally installed at completion of drywall texture, when drywall subcontractor has completed work and before painting subcontractor starts priming drywall. However, there is some flexibility with AeroBarrier installation at post-drywall stage. It can also take place after taping & mudding but before texture, after priming, and even after painting if no other option is available. Note that the AeroBarrier sealant does not adhere to any vertical surfaces (other than at air leakage points.)</li>
             <li>
             If a post-drywall installation of AeroBarrier has been required because the building has a Vented Attic (Unconditioned Attic), it is possible to “hybridize” an AeroBarrier installation with <em>only</em> hanging/taping/mudding the ceiling drywall first and not the drywall on walls. The ceiling drywall isolates the vented attic, and then an AeroBarrier installation can seal that drywall air barrier as well as seal the exterior walls of the building (pre-drywall.)
             </li>
           </ul>
           <p><strong>Conditions Required</strong></p>
           <ul>
             <li>An installation at this stage requires completion of hanging of all drywall, and completion of taping & mudding. The exception to this rule is as described above where ceiling drywall might only need to be installed.</li>
             <li>Caulking must be completed between all HVAC register boots and drywall (as is California Code.)</li>
             <li>NO FINISHES HAVE BEEN INSTALLED YET.</li>
             <li>In the case where baseboard is to be flush with drywall (not attached over drywall) using a Fry Reglet or alternate, and thus the drywall does not reach the bottom plate, this will require that the opening below the drywall be sealed off with a plastic membrane that is to remain in place with the baseboard to be installed over it. If your project has this type of baseboard installation, please consult with Western AeroBarrier.</li>
             <li>All materials, supplies, equipment, tools must be moved away from exterior walls and covered up to protect them from AeroBarrier sealant.  Plastic tarping should be draped over anything that needs to be protected from sealant that is falling out of suspension. <strong> At the completion of air sealing, the sealant will fall out of the air and onto the floors of the building.  This will make the floors tacky but by the next day most of that tackiness will be gone.  The sealant left on the floors will not interfere with the installation of any type of flooring. </strong> Please contact Western AeroBarrier if you have any concerns about the sealant being left on the floor.</li>
             <li>No other trades can be working on the interior of the home during the day(s) of AeroBarrier installation.  Any trades working on the exterior must keep clear of the entrance area to allow access for the AeroBarrier rig, equipment, and crew.</li>
           </ul>
           <Button href={postDrywall} target="_blank">Download PDF - Post Drywall Checklist</Button>
           <hr />
           <h3>Pre-Drywall</h3>
           <p><strong>When This Installation Takes Place:</strong></p>
           <ul>
             <li>AeroBarrier installation shall generally take place immediately prior to insulation being installed, and all work on installation of mechanicals has been completed. The optional exception to this rule is if any of the insulation is to be open-cell or closed-cell foam, in which case an AeroBarrier installation optimally takes place immediately after any spray foam installation. A home may be entirely insulated with spray foam, or it may be a hybrid (i.e. spray foam at underside of roof sheathing, and fiberglass insulation in exterior walls.) In the case of a hybrid situation, again AeroBarrier is optimally installed right after the spray foam phase but before installation of any other type of insulation.</li>
           </ul>
           <p><strong>Conditions Required:</strong></p>
           <ul>
             <li>An installation at this stage requires an UNVENTED ATTIC (Conditioned Attic.) If the attic is ventilated, a Post-Drywall installation of AeroBarrier is necessary.</li>
             <li>The house must be entirely sheathed with OSB or Plywood. If the house is not entirely sheathed, and building paper is exposed from the interior of the home, then either these “voids” must also be sheathed to make the home ready or a Post-Drywall installation of AeroBarrier is required.</li>
             <li>All doors and windows must be installed, and all Title-24 foaming around doors and windows must be completed.</li>
             <li>There are to be no large holes/openings in the envelope which would require the Western AeroBarrier team to install wood blocking and OSB/plywood to fill. <strong><em>It is required that prior to arrival on site of the Western AeroBarrier team, all building envelope penetrations have been sealed with foam</em></strong> (i.e. foaming with a foam gun around ducting and pipes that penetrate the exterior sheathing.) As part of the Western AeroBarrier installation, our team will inspect the building to assure that there are no large holes in the envelope, and we shall foam-in around building penetrations that have been “missed”. Though the AeroBarrier sealant can fill any hole in the envelope under ½” in size, it is more economical to seal easy-to-fix holes with a foam gun rather than with AeroBarrier sealant (the AeroBarrier sealing process will be faster, and less AeroBarrier sealant will be required.)</li>
             <li>There must be a door opening that is small enough to accommodate a “Blower Door”. The maximum size of a doorway for fitting a blower door is 8.0’ tall and 3.0’ wide. We cannot mount a blower door where there is a pivot door – if the home has a pivot door, we need to mount the blower door in another doorway.</li>
             <li>All materials, supplies, equipment, tools must be moved away from exterior walls and covered up to protect them from AeroBarrier sealant. Plastic tarping should be draped over anything that needs to be protected from sealant that is falling out of suspension. <strong>At the completion of air sealing, the sealant will fall out of the air and onto the floors of the building. This will make the floors tacky but by the next day most of that tackiness will be gone. The sealant left on the floors will not interfere with the installation of any type of flooring.</strong> Please contact Western AeroBarrier if you have any concerns about the sealant being left on the floor.</li>
             <li>No other trades can be working on the interior of the home during the day(s) of AeroBarrier installation.  Any trades working on the exterior must keep clear of the entrance area to allow access for the AeroBarrier rig, equipment, and crew.</li>
           </ul>
           <Button href={preDryWall}  target="_blank" className="mb-4">Download PDF - Pre-Drywall Checklist</Button>
          </Container>
        </section>
      </Layout>
);

export default aboutTheAuthor;